import * as React from 'react'
import { graphql } from 'gatsby'
const { useEffect } = React;
import { Element } from 'react-scroll'
import { isMobile } from "react-device-detect";
import WebFont from "webfontloader";
import { colors } from '../styles/cards'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { RecollectionEyeCatch } from '../components/cards/recollection/index'
import noise from '../img/bg/bkg-noise.png'

interface ContainerProps {
  data: {
    photos: any;
    desktop: any;
    eyecatch: any;
    members: any;
    content: any;
  }
}

const IndexPage: React.FC<ContainerProps> = ({ data }) => {

  useEffect(() => {
    WebFont.load({
      typekit: {
        id: 'vsu8loo'
      }
    });
  });

  useEffect(() => {
    if (!isMobile) {
      window.location.replace("https://google.com")
    }
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#' + colors.eyecatch;
    document.body.style.backgroundImage = noise!;
  });

  // useEffect(() => {
  //   function setBounceColor() {
  //     document.body.style.backgroundColor = '#' + colors.eyecatch;
  //     document.body.style.backgroundImage = noise!;
  //   }

  //   document.addEventListener("scroll", setBounceColor, { passive: false });

  //   return () => {
  //     document.removeEventListener("scroll", setBounceColor, false);
  //   };
  // }, []);

  return (
    <IndexLayout>
        <iframe width="100%" height="300" src="https://www.youtube.com/embed/6lQkkPgCNSo?rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope;" allowfullscreen />
      <Page>
        {/* アイキャッチ */}
        <Element name={`scroll-to-element-0`} className='element' key={`element-0`}>
          <RecollectionEyeCatch
            containerHeight={'100vw'}
            photo={data.photos.edges[0]}
            eyecatch={data.eyecatch.edges[0]}
            desktop={data.desktop} />
        </Element>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

/* eslint no-undef: off */
export const pageQuery = graphql`
  query HomeRecollectionQuery {
    desktop: file(relativePath: {eq: "bg/bkg-noise.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    eyecatch: allFile(filter: {relativeDirectory: {eq: "eyecatch" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }

    members: allFile(filter: {relativeDirectory: {eq: "members" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    photos: allFile(filter: {relativeDirectory: {eq: "photos" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }

    content: allFile(filter: {relativeDirectory: {eq: "content" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
}
`; ``
